<template>
  <v-tabs
    class="tab-component"
    v-model="tab"
    center-active
    slider-size="4"
    ref="tab"
    show-arrows
  >
    <v-tabs-slider class="bg-blue-300"></v-tabs-slider>
    <v-tab :style="{ width: tabWidth }" link to="/home/report/finish">
      <div class="font-xxx-large total">{{ totalFinish }}</div>
      <div class="font-x-large tab-title">
        {{ $t("main.report.tab.finish") }}
      </div>
    </v-tab>
    <v-tab :style="{ width: tabWidth }" link to="/home/report/paid">
      <div class="font-xxx-large total">{{ totalPaid }}</div>
      <div class="font-x-large tab-title">{{ $t("main.report.tab.paid") }}</div>
    </v-tab>
  </v-tabs>
</template>

<script>
import WorkListService from "@/services/work-list";
import moment from "moment";

export default {
  name: "TabComponent",
  data() {
    return {
      tab: null,
      tabWidth: "267 px",
      totalFinish: 0,
      totalPaid: 0,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 0,
      },
    };
  },
  mounted() {
    this.getLatestTabTotals();
  },
  methods: {
    async getLatestTabTotals() {
      var timeStart = moment().startOf("date").format("YYYY-MM-DD HH:mm");
      var timeEnd = moment().endOf("date").format("YYYY-MM-DD HH:mm");
      var result = await WorkListService.countPatientsReport(
        timeStart,
        timeEnd
      );
      this.totalFinish = result.finish;
      this.totalPaid = result.paid;
    },
  },
};
</script>

<style lang="scss">
.tab-component {
  .v-item-group {
    height: 84px;
    border-bottom: 1px solid #cacbcc;
  }

  .v-slide-group {
    &__prev {
      position: absolute;
      background-color: transparent;
      left: 0;
      top: 0;
      height: 84px;
      width: 52px;
      z-index: 100;

      &--disabled {
        display: none;
      }

      .v-icon {
        color: $blue-300;
        &::before {
          font-size: 42px;
        }
      }
    }

    &__next {
      position: absolute;
      right: 0;
      top: 0;
      height: 84px;
      width: 52px;
      z-index: 100;

      &--disabled {
        display: none;
      }

      .v-icon {
        color: $blue-300;
        &::before {
          font-size: 42px;
        }
      }
    }
  }

  .v-tab {
    min-width: 267px;
    height: 84px;
    flex-direction: column;
    // border-bottom: 1px solid #cacbcc;
    color: $neutral-30 !important;
    &--active {
      color: $blue-300 !important;
    }
    .total {
      line-height: 40px;
    }
    .tab-title {
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 20px;
      opacity: 0.7;
    }
  }
}
</style>